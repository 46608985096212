* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Outfit", sans-serif;
  letter-spacing: 0.01rem !important;
  line-height: 28px !important;
}

/* Mui styles override */

.MuiTypography-root {
  font-family: "Outfit", sans-serif !important;
  letter-spacing: 0.01rem !important;
  line-height: 28px !important;
}

.MuiGrid-root {
  width: 100% !important;
}
